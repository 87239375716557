<template>
  <v-card class="elevation-3 loginForm">
    <v-toolbar dark color="primary">
      <v-img
        class="shrink mr-2"
        contain
        :src="require('@/assets/logo.png')"
        transition="scale-transition"
      />

      <v-toolbar-title>{{ $t("requestPasswordChange") }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-if="!showText">
        <v-text-field
          v-model="email"
          prepend-icon="mdi-account"
          name="login"
          :label="$t('email')"
          type="text"
          outlined
        ></v-text-field>
      </v-form>
      <p v-else>Check your email inbox!</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!showText"
        :loading="loading"
        color="primary"
        large
        rounded
        @click="send"
        >{{ $t("send") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import { passwordChangeRequest } from "@/api";
export default {
  data: () => ({
    email: "",
    loading: false,
    showText: false,
  }),
  created() {
    if (this.$store.state.user) {
      this.email = this.$store.state.user.email;
    }
  },
  methods: {
    async send() {
      if (this.email.trim() === "") return;
      this.loading = true;
      await passwordChangeRequest(this.email);
      this.showText = true;
      this.loading = false;
    },
  },
};
</script>

<style>
.loginForm {
}
.loginForm header {
  height: 124px !important;
  padding-top: 20px;
  box-shadow: none !important;
}
.loginForm .v-toolbar__content {
  display: grid;
  text-align: center;
  grid-gap: 30px;
}

.loginForm .v-card__actions {
  padding: 20px;
}
.loginForm .v-card__actions button {
  font-size: 1em;
  padding: 0 20px !important;
  font-weight: bold;
}
.loginForm .v-card__text {
  padding: 26px 16px 0 16px;
}
</style>
